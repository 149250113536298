body {
    margin: 0;
    font-size: var(--text-size);
    font-weight: 400;
    overflow-x: hidden;

    .content {
        width: 100%;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    .bold {
        font-weight: 700;
    }
}

/* Material Symbols */
span {
    &.size-20 {
        font-size: 20px;
        font-variation-settings: 'FILL' 1, 'wght' 300, 'GRAD' 0, 'OPSZ' 20;
   }
}
span {
    &.size-24 {
        font-size: 24px;
        font-variation-settings: 'FILL' 1, 'wght' 300, 'GRAD' 0, 'OPSZ' 24;
    }
    &.size-24-outlined {
        font-size: 24px;
        font-variation-settings: 'FILL' 0, 'wght' 300, 'GRAD' 0, 'OPSZ' 24;
    }
}
span {
    &.size-48 {
        font-size: 48px;
        font-variation-settings: 'FILL' 1, 'wght' 300, 'GRAD' 0, 'OPSZ' 48;
   }
}