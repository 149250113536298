body {
  margin: 0;
  font-size: var(--text-size);
  font-weight: 400;
  overflow-x: hidden;
}
body .content {
  width: 100%;
}
body a {
  text-decoration: none;
  color: inherit;
}
body .bold {
  font-weight: 700;
}

/* Material Symbols */
span.size-20 {
  font-size: 20px;
  font-variation-settings: "FILL" 1, "wght" 300, "GRAD" 0, "OPSZ" 20;
}

span.size-24 {
  font-size: 24px;
  font-variation-settings: "FILL" 1, "wght" 300, "GRAD" 0, "OPSZ" 24;
}
span.size-24-outlined {
  font-size: 24px;
  font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "OPSZ" 24;
}

span.size-48 {
  font-size: 48px;
  font-variation-settings: "FILL" 1, "wght" 300, "GRAD" 0, "OPSZ" 48;
}

.sidebar-drawer {
  overflow-x: hidden;
  gap: 16px;
  background-color: #ffffff;
  box-shadow: inset -1px 0px 0px #e2e3e4 !important;
  border-right: 0px !important;
}
.sidebar-drawer .top-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px !important;
  gap: 16px;
  height: 66px;
}
.sidebar-drawer .top-content .logo-container {
  padding: 16px;
  gap: 10px;
  height: 56px;
  border-radius: 4px;
}
.sidebar-drawer .top-content .logo-container img {
  width: 105.62px;
  height: 24px;
  cursor: pointer;
}
.sidebar-drawer .top-content .avatar-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 16px 0px 0px;
  min-width: 40px;
  height: 40px;
}
.sidebar-drawer .top-content .avatar-container .avatar {
  border-radius: 64px;
  padding: 0px;
  align-items: center;
}
.sidebar-drawer .top-content .top-content-name {
  font-style: normal;
  font-size: 16px !important;
  line-height: 150% !important;
  letter-spacing: 0.15px;
  color: #161718;
}
.sidebar-drawer .top-content .top-content-description {
  font-style: normal;
  font-size: 14px !important;
  line-height: 143% !important;
  letter-spacing: 0.15px;
  color: #464a4e;
}
.sidebar-drawer .main-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px 16px !important;
}
.sidebar-drawer .sidebar-link {
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  border-radius: 4px;
}
.sidebar-drawer .sidebar-link .sidebar-link-icon {
  padding: 0px 16px 0px 0px;
  width: 24px;
  height: 24px;
  min-width: 0px;
}
.sidebar-drawer .sidebar-link .sidebar-link-text {
  padding: 4px 0px !important;
  width: 136px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #161718;
  margin: 0;
}

.header {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: 56px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.header .header-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  box-shadow: inset 0px -1px 0px #E2E3E4;
  width: 100%;
  height: 56px;
  background: #FFFFFF;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.header .header-actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 64px;
  height: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.header .header-actions .icon-notifications {
  padding: 0px;
}
.header .header-actions .icon-notifications svg {
  width: 24px;
  height: 24px;
}
.header .header-actions .icon-user-menu {
  padding: 0px;
}
.header .header-actions .icon-user-menu svg {
  width: 24px;
  height: 24px;
}
.header .header-actions .MuiIconButton-root {
  height: 24px;
  width: 24px;
}
.header .header-actions .MuiIconButton-root .MuiTouchRipple-root {
  width: 100%;
  height: 100%;
}
.header .header-actions .MuiIconButton-root :nth-child(2) {
  margin-left: 16px;
}
.header .header-icon {
  color: #464a4e;
}

.content-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
}

.content {
  position: relative;
  min-height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
}

.main-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  max-width: 100%;
  overflow-x: auto;
  gap: 24px;
  margin-bottom: 53px;
  background: #ffffff;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
}
.main-content > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
}
.main-content .page-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  padding: 0px;
  width: 100%;
  height: 64px;
  gap: 24px;
}
.main-content .page-title .page-title-content {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
}
.main-content .page-title .page-title-header {
  order: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  gap: 8px;
}
.main-content .page-title .page-title-header > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}
.main-content .page-title .page-title-header .page-title-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%;
  letter-spacing: 0.25px;
  color: #161718;
}
.main-content .page-title .page-title-actions {
  order: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
}
.main-content .page-title .page-title-divider {
  width: 100%;
  box-sizing: border-box;
  border-color: #E2E3E4;
}
.main-content .page-title .page-breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
}
.main-content .page-title .page-breadcrumbs .breadcrumbs-link {
  padding: 0px;
  gap: 4px;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #464a4e;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: none;
  border-top: 1px solid #e2e3e4;
  padding: 16px 24px;
}
.footer .footer-typography {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.15px;
}
.footer .footer-typography .poweredByLogo {
  height: 16px;
  margin-left: 6px;
}

.Mui-selected * {
  color: #0076fb;
}

.grid-toolbar {
  border: 1px solid #e2e3e4;
  border-bottom: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.grid-data {
  border: 1px solid #e2e3e4;
  border-radius: 0 0 4px 4px !important;
}